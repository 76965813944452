












































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'

@Component({ name: 'Webhook' })
export default class Webhook extends VueBase {
  public Hooks: any = [
    {
      url: '',
      type_id: '',
      headers: '',
    },
  ]
  public hookTypes = []
  public add() {
    this.Hooks.push({
      url: '',
      type_id: '',
      headers: '',
    })
  }
  public async minus(index: number) {
    if (!this.Hooks[index].id) {
      this.Hooks.splice(index, 1)
      return
    }
    this.$confirm(
      this.$t('views.webhook.deleteConfirm') as string,
      this.$t('views.webhook.deleteConfirmPop') as string,
      {
        confirmButtonText: this.$t('views.userList.submit') as string,
        cancelButtonText: this.$t('views.userList.cancel') as string,
        type: 'warning',
      }
    ).then(async (i: any) => {
      const res = await this.services.setting.webhook_delete({
        id: this.Hooks[index].id,
      })
      if (res.status === 201) {
        this.$message.success(res.msg)
        this.Hooks.splice(index, 1)
        return
      }
      this.$message.error(res.msg)
    })
  }
  public async getTypeList() {
    const res = await this.services.setting.webhook_type()
    this.hookTypes = res.data.result
  }
  public async getWebhook() {
    const res = await this.services.setting.webhook_get()
    res.data.result &&
      res.data.result.forEach((item: any) => {
        if (item.headers) {
          item.headers = JSON.stringify(item.headers)
        } else {
          item.headers = ''
        }
      })
    this.Hooks = res.data.result
  }
  public async save(index: any) {
    const req = JSON.parse(JSON.stringify(this.Hooks[index]))
    if (req.headers) {
      req.headers = JSON.parse(req.headers)
    }
    const res = await this.services.setting.webhook_set(req)
    if (res.status === 201) {
      this.$message.success(res.msg)
      this.Hooks[index].id = res.data.id
      return
    }
    this.$message.error(res.msg)
  }
  created() {
    this.getTypeList()
    this.getWebhook()
  }
}
